
import ReactTypingEffect from 'react-typing-effect';

/*--------------------
* Banner Section
----------------------*/
export default function Banner() {
    return (
        <>
            <section data-scroll-data="0" id="home" className="home-section pt-[120px] pb-[80px] lg:pt-[180px] lg:pb-[100px] bg-lime-50 relative overflow-hidden">
                <div className="container relative z-[1]">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6">
                            <div className="text-center lg:text-start mb-[50px] lg:mb-0">
                                <h6 className="text-black uppercase text-[14px] md:text-[16px] tracking-[2px] font-[600] mb-[20px] md:mb-[30px]">👋, Bienvenue a SIM; Smart ISO Management Consulting</h6>
                                <h1 className="text-black font-[300] text-[30px] lg:text-[50px] xl:text-[50px] leading-[1] mb-[25px] md:mb-[35px]">Conseil <span id="type-it"><ReactTypingEffect text={["Environnemental", "Industriel"]}
                                        speed="100" typingDelay="500" eraseDelay="200" /></span></h1>
                                <p className="text-[16px] md:text-[20px] mb-[8px] md:mb-[16px]">Laissez-nous vous aider à résoudre vos doutes et surtout nous avons @ISORGA.</p>
                                <div className="pt-[10px]">
                                    {/* <Link className="px-btn px-btn-theme" to="contactus">¿Te ayudamos?</Link>  */}
                                    <a href="https://sim-consulting.net/catalogue.pdf" target="_blank" className="px-btn px-btn-theme" rel="noreferrer">Telecharger le catalogue</a>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-6">
                            <div className="home-image text-center">
                                {/* <img src="img/home-banner2.png" title="Banner" alt="Banner" /> */}
                                <img src="img/consultor.jpg" title="Banner" alt="Banner" className="rounded-lg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
