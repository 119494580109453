import axios from "axios";
import { useState } from "react";

/*--------------------
* Contact Section
----------------------*/

export default function Contact() {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [camposIncompletos, setCamposIncompletos] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);

  const validarEmail = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const enviarFormulario = async () => {
    if (nombre.trim() === "" || email.trim() === "" || mensaje.trim() === "") {
      setCamposIncompletos(true);
      setEmailInvalido(false);
    } else if (!validarEmail(email)) {
      setEmailInvalido(true);
      setCamposIncompletos(false);
    } else {
      try {
        await axios.post("https://formspree.io/f/meojdzra", {
          nombre,
          email,
          mensaje,
        });
        setNombre("");
        setEmail("");
        setMensaje("");
        setCamposIncompletos(false);
        setEmailInvalido(false);
        console.log("Formulario enviado correctamente");
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
      }
    }
  };

  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="p-[25px] md:p-[35px] bg-white">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">
                  Contactez-nous
                </h6>
                <p className="text-[18px] mb-[30px]">
                  De manière amicale et sans engagement, surtout sans
                  engagement.
                </p>
                {camposIncompletos && (
                  <p className="text-red-500">Tous les champs sont requis.</p>
                )}
                {emailInvalido && (
                  <p className="text-red-500">L'email est invalide.</p>
                )}
                <form id="contact-form" method="POST" onSubmit={(e) => e.preventDefault()}>
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">Nom</label>
                        <input
                          name="Name"
                          id="nombre"
                          placeholder="Nom *"
                          className="form-control"
                          type="text"
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input
                          name="Email"
                          id="email"
                          placeholder="Email *"
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">Message</label>
                        <textarea
                          name="message"
                          id="mensaje"
                          placeholder="Message *"
                          rows="4"
                          className="form-control"
                          value={mensaje}
                          onChange={(e) => setMensaje(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="send">
                        <button
                          className="px-btn px-btn-theme"
                          type="button"
                          onClick={enviarFormulario}
                        >
                          Envoyer un message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <div className="pb-10">
                  <img
                    className="w-full"
                    src="img/logosimblanco.png"
                    title=""
                    alt="imagen de contacto"
                  />
                </div>
                <ul className="contact-infos">
                  <li>
                    <div className="icon bg-1">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="col">
                      <h5>Telephone:</h5>
                      <p>+212(0)522390026</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-2">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Mail</h5>
                      <p>contact@sim-consulting.net</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-3">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Adresse:</h5>
                      <p>
                        265, BD.Zerktouni 9ª Etage, Nº 92 <br />
                        Casablanca (Maroc)
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
