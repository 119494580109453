
import { Link } from "react-scroll";

/*--------------------
* About Section
----------------------*/
export default function About() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
                            <img className="mx-auto" src="img/fabrica.jpg" title="Banner" alt="Banner" />
                        </div>
                        <div className="lg:col-span-6 lg:pl-12">
                            <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">Nous sommes un cabinet de conseil formé par des professionnels et des partenaires avec plus de 20 ans d’expérience dans le monde industriel.</h3>
                            <p className="text-[16px] md:text-[18px]">Avec notre expérience et nos partenaires locales et à l’étranger dans le monde industriel, nous pouvons aider les entreprises à la fois à faire face à leurs responsabilités et à leur fournir des outils informatiques spécialement conçus pour résoudre leurs problèmes. </p>
                            <div className="grid grid-cols-12 pt-5">
                                <div className="col-span-6 sm:col-span-4">
                                    <h5 className="text-[26px] text-emerald-600 font-[600]">285+</h5>
                                    <span>Projets réalisés </span>
                                </div>
                                <div className="col-span-6 sm:col-span-4">
                                    <h5 className="text-[26px] text-emerald-600 font-[600]">190+</h5>
                                    <span>Clients satisfaits</span>
                                </div>
                            </div>
                            <div className="pt-6">
                                <Link className="px-btn px-btn-theme mr-4" to="contactus">Contacter nous</Link>
                                <Link className="px-btn px-btn-dark" to="portfolio">Services</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
