
/*--------------------
* Skill Section
----------------------*/
function Skill(props) {
    return (
        <>
            <div className="col-span-6">
                <div className={props.id.main + " feature-box-02 "}>
                    <div className="icon">
                        <i className={props.id?.icon}></i>
                    </div>
                    <h6>{props.title}</h6>
                </div>
            </div>
        </>
    );
}

/*--------------------
* Skill List Section
----------------------*/
export default function SkillList() {

    const skill_list = [
        {id:{main:'bg-1', icon:'fa fa-list'}, title:'ISO 9001. QUALITÉ' },
        {id:{main:'bg-2', icon:'fab fa-pagelines'}, title:'ISO14001. ENVIRONNEMENT' },
        {id:{main:'bg-3', icon:'fab fa-react'}, title:'IS05001. ENERGIE' },
        {id:{main:'bg-4', icon:'fas fa-users-cog'}, title:'ISO26000. RSC' },
        {id:{main:'bg-5', icon:'fas fa-balance-scale-left'}, title:'GRI4. DEVELOPPEMENT DURABLE' },
        {id:{main:'bg-6', icon:'fas fa-exclamation-circle'}, title:'ISO31000. RISQUES' },
    ]

    return (
        <>
            <section data-scroll-data="2" id="skill" className="section experience-section !pb-0">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1">
                        <div className="lg:col-span-6 lg:pl-9">
                            <div className="section-heading">
                                <h3 className="m-0 text-xl"><span>Support pour les systemes</span></h3>
                            </div>
                            <div className="skill-box">
                                <div className="grid gap-4 grid-cols-12">
                                    {
                                        skill_list.map((val, i)=>{
                                            return <Skill key={i} id={val.id} title={val.title}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-6 text-center pt-[40px] mt-10 mb-10">
                            <img className="mx-[auto] ml-20 rounded-lg mt-50 h-full" src="img/erp.jpeg" title="" alt="Logo de skills"/>
                            {/* <img className="mx-[auto]" src="img/skills.png" title="" alt="Logo de skills"/> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
