import { useState } from "react";
import { Link } from "react-scroll";

/*--------------------
* Proejct Section
----------------------*/

function Proejct(props) {
    return (
        <>
            <div className="px-modal mfp-hide ">
                <div className="single-project-box ">
                    <div className="grid grid-cols-12 gx-3">
                        <div className="col-span-12 md:col-span-7 mb-10 md:mb-0">
                            <div className="grid grid-cols-2 gap-2 pt-10 ">
                                {props.projectDetails.images.map((img, i) => {
                                    return ( 
                                        <div key={`image_${i}`} className="col-span-1 border-2 border-emerald-200">
                                            <img className="border" src={img} title="" alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-5 md:pl-10 lg:pl-14">
                            <h4 className="font-[600] text-[25px] text-black mb-4 pb-4 border-b">{props.projectDetails.title}</h4>
                            <p className="mb-3">{props.projectDetails.description}</p>
                            <p>{props.projectDetails.subDescription}</p>
                            <ul className="m-0 p-0 pt-7 list-none">
                                {props.projectDetails.details.map((detail, i) => {
                                    return (
                                        <li key={`detail_${i}`} className="flex py-2">
                                            <span className="w-[100px] font-[600] text-black">{detail.title}:</span>
                                            <span>{detail.description}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <button className="px-close" onClick={ props.closePopup }><i className="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </>
    );
}


/*--------------------
* Work Section
----------------------*/

export default function Work() {
    const [showModal, setShowModal] = useState(0);
    const closePopup = () => {
        setShowModal(0);
    }

    const projectDetails = [
        {
            title: 'Isorga Legal',
            description: 'Grâce à cet outil, vous pourrez détecter les réglementations et bien sûr les exigences applicables dans votre organisation, en établissant dans un premier temps quelques conditions qui définiront votre profil permettant évaluer sa conformité.',
            subDescription: 'Ce service répond aux exigences des normes ISO.',
            images: ['img/legal-1.png', 'img/legal-2.png', 'img/legal-3.png', 'img/legal-5.png'],
            details: [
                {
                    title: 'Type',
                    description: 'Identification du règlement',
                },
                {
                    title: 'Portées',
                    description: 'Environnement, Sécurité Industrielle, Sécurité du Travail',
                },
                {
                    title: 'Portées',
                    description: 'État',
                },
                {
                    title: 'Langues',
                    description: 'FR, ES, EN, ',
                },
               
            ]
        },
        {
            title: 'Isorga Systemes',
            description: 'Pour gérer la documentation, les non-conformités, les indicateurs, les risques et tous les points établis dans toute norme ISO avec une structure de haut niveau, AUDEL a développé son propre outil (hautement personnalisé) qui permet le contrôle de tous ces éléments..',
            subDescription: 'Entièrement personnalisé avec un grand nombre de modules.',
            images: ['img/sistemas-2.png', 'img/sistemas-3.png', 'img/sistemas-4.png', 'img/sistemas-1.png'],
            details: [
                {
                    title: 'Normes ISOmas',
                    description: 'ISO9001, ISO14001, ISO 31000 et...',
                },
                {
                    title: 'Rapports',
                    description: 'Excel, PDF',
                },
                {
                    title: 'Modules',
                    description: 'Documentaire, Non-Conformités, Formation,...',
                },
            ]
        },
        
       
    ]
    return (
        <>
            <section data-scroll-data="3" id="portfolio" className="section bg-orange-50">
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>ISORGA (OUTILS DÉVELOPPÉS)</span></h3>
                        </div>
                    </div>
                    <div className="lightbox-gallery portfolio-box">`
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/legal.png" title="" alt="Isorga Legal" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>Exigences légales</span></h6>
                                    <h4>Isorga Legal</h4>
                                    <p>Compilation des réglementations ENVIRONNEMENTALES, SÉCURITÉ INDUSTRIELLE et SÉCURITÉ DU TRAVAIL, pour vous tenir au courant des exigences applicables à votre organisation</p>
                                    <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(1)}>Plus d'informations </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/sistemas.png" title="" alt="Isorga Sistemas" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>Les systèmes de gestion</span></h6>
                                    <h4>Isorga Systèmes</h4>
                                    <p>Que vous souhaitiez contrôler la documentation en vigueur, comme les Non-Conformités ou notre Checklist, ainsi que connaître les risques et les gérer de manière appropriée, sans oublier la formation et les compétences, nous avons cet outil qui s'adapte parfaitement à vos besoins.</p>
                                    <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(2)}>Plus d'informations </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>

            {showModal ? (
                <>
                    <Proejct closePopup={closePopup} projectDetails={projectDetails[showModal - 1]}></Proejct>
                </>
            ): null}
        </>
     );
}
