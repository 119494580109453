/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: { main: "bg-1", icon: "fas fa-phone-alt" },
      title: "Conseil Industriel",
      description:
        "Avec les changements si continus tant sur les aspects réglementaires que sur les installations, nous vous assistons depuis SIM pour être à jour pour répondre aux exigences établies",
    },
    {
      id: { main: "bg-2", icon: "fas fa-laptop" },
      title: "Identification de la législation ",
      description:
        "Nous identifions pour vous les réglementations qui s'appliquent à vous et nous détectons les exigences et la législation que vous devez respecter, avoir et évaluer, le tout avec notre propre outil.",
    },
    {
      id: { main: "bg-3", icon: "fas fa-exclamation-triangle" },
      title: "Systèmes de management",
      description:
        "Vous disposez d’un système de management implémenté ou souhaitez en mettre un en place, SIM peut aider votre entreprise à se hisser à un niveau grâce à l’aide qu’apportent les systèmes de management. Tout cela en Qualité ISO 9001, Environnement ISO 14001, Energie ISO 50001, et autres…",
    },
    {
      id: { main: "bg-4", icon: "fas fa-columns" },
      title: "Projets",
      description:
        "Nous vous aidons à mettre en œuvre les projets d’amélioration visant à atteindre la performance en développement durable, que cela soit en traitement d’eau, efficacité énergétique, gestion de déchets, … tout cela dans le respect des lois applicables",
    },
    {
      id: { main: "bg-5", icon: "fas fa-ruler-vertical" },
      title: "Audits Internes",
      description:
        "Et pour mesurer le degré de la mise en œuvre des systèmes de management, SIM réalise des audits internes de ces derniers que cela soit en Qualité ISO 9001, Environnement ISO 14001, Energie ISO 50001, santé et sécurité au travail ISO 45001, et autres…, permettant à la détection des écarts et des opportunités d’amélioration.",
    },
    {
      id: { main: "bg-6", icon: "fas fa-globe" },
      title: "Logiciel personnalisée (SaaS)",
      description:
        "Nous concevrons des outils informatiques sur mesure pour gérer les propres activités de l’organisation afin qu’elles puissent être gérées de manière optimale, surtout, surveiller les points établis dans les systèmes mis en œuvre, tels que le contrôle documentaire, les non-conformités, l'analyse des risques,... ",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gray"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Services</span>
              </h3>
              <a
                href="/catalogue.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                En apprendre plus sur notre travail
              </a>{" "}
            </div>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
            {service_list.map((val, i) => {
              return (
                <Service
                  key={i}
                  id={val.id}
                  title={val.title}
                  description={val.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
